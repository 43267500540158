.previewAssinatura {
    width        : 100%;
    border       : 1px solid var(--primaria);
    border-radius: 1rem;
    padding      : 2rem;
}

@keyframes fade {
    from {
        opacity: 0;
    }
}

@keyframes fadeout {
    to {
        opacity: 0;
    }
}

.iconesMapa {
    transform      : translate(-50%, -100%);
    animation      : fade 0.5s alternate;
    transition     : opacity 1s;
}