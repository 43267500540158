@import './styles/espacamento.scss';
@import './styles/estrutura.scss';
@import './styles/imagens.scss';
@import './styles/override.scss';

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

$theme-colors: ("primary": #6558f5,
	"danger": #dc3545,
	"success": #28a745,
	"warning": #ffc107,
	"info": #17a2b8,
	"offWhite": #eaeef1,
	"neutral": #4b5c6b,
	"bright": #b2c6d7,
	"light": #dde6ef,
	"teste": #5100ff,
);


@import "../node_modules/bootstrap/scss/bootstrap.scss";

:root {
	font-size  : 17px;
	line-height: 24px;
	font-weight: 420;

	--primaria: #777777;
	--grey-200: #202020;
	--grey-300: #383838;
	--grey-400: #5b5b5b;
	--grey-500: #828282;
	--grey-600: #aaaaaa;
	--grey-700: #cecece;
	--grey-800: #eeeeee;
	--white   : #fff;

	--swiper-pagination-color: var(--primaria);

	color-scheme            : light;
	font-family             : 'Rubik', sans-serif;
	font-synthesis          : none;
	text-rendering          : optimizeLegibility;
	-webkit-font-smoothing  : antialiased;
	-moz-osx-font-smoothing : grayscale;
	-webkit-text-size-adjust: 100%;
}

.corPrimaria {
	color : var(--primaria);
	filter: drop-shadow(0 0 1px black) drop-shadow(0 0 1px black) drop-shadow(0 0 2px black);
}

*,
*:before,
*:after {
	box-sizing : border-box;
	user-select: none;
}

.v80 {
	position: relative;
	height  : 100%;
	z-index : 1000;
}

.rodapeMapa {
	background-color: #fff;
	border-radius   : 2.5rem 2.5rem 0 0;
	height          : 70px;
	width           : 100vw;
	z-index         : 1000;

	display        : flex;
	font-size      : 2rem;
	padding        : 1.5rem;
	justify-content: space-around;
	align-items    : center;
}

.fw100 {
	font-weight: 100;
}

.fw200 {
	font-weight: 200;
}

.fw300 {
	font-weight: 300;
}

.fw400 {
	font-weight: 400;
}

.fw500 {
	font-weight: 500;
}

.fw600 {
	font-weight: 600;
}

.fw700 {
	font-weight: 700;
}

.fw800 {
	font-weight: 800;
}

.fw900 {
	font-weight: 900;
}

.bgPri {
	background-color: var(--primaria) !important;
}

.bgPriBright {
	background-color: var(--primaria-bright) !important;
}

.bgPriBrighter {
	background-color: var(--primaria-brighter) !important;
}

.bgPriPura {
	background-color: var(--primaria-pura) !important;
}

.bgPriDark {
	background-color: var(--primaria-Dark) !important;
}

.swiper {
	width: 120%;
	left : -10%;
}

.text-area {
	font-size: 0.85rem;
	height   : 5rem;
	width    : 100%;
	resize   : none;
}

.ajustarVertical {
	display        : flex;
	flex-direction : column;
	justify-content: center;
	margin         : 0 2rem;
}

a {
	font-weight    : 600;
	color          : var(--primaria);
	text-decoration: inherit;
	transition     : 0.1s;
}

a:hover {
	color: #535bf2;
}

body {
	color      : var(--primaria-darkest);
	margin     : 0;
	display    : flex;
	place-items: center;
	min-width  : 320px;
	min-height : 100vh;
}

h1 {
	font-size  : 2rem;
	line-height: 1.1;
}

button {
	border-radius   : 8px;
	border          : 1px solid transparent;
	padding         : 0.6em 1.2em;
	font-size       : 16px;
	font-weight     : 500;
	font-family     : inherit;
	background-color: var(--primaria);
	color           : var(--white);
	cursor          : pointer;
	transition      : 0.4s;
	transition      : border-color 0.25s;
}

button:hover {
	border-color: var(--primaria);
}

button:focus {
	background-color: white;
	color           : var(--primaria-brightest);
	transition      : 0.4s;
}

button:disabled,
button:disabled:hover {
	border-color    : white;
	background-color: var(--grey-700);
	color           : var(--grey-500);
	transition      : 0.4s;
	opacity         : 0.33;
}

label {
	font-weight: 500;
	font-size  : 0.95em;
	color      : var(--grey-300);
}

h3 {
	margin-bottom: 0;
}

p {
	margin-top: 0;
}

.red {
	color      : red !important;
	font-weight: 1000;
}

.redBorder {
	border-color: red !important;
	transition  : 0.4s;
}

.ancoraL {
	position: absolute;
	display : flex;
}

#ifood {
	&::-webkit-scrollbar {
		width: 10px;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: transparent;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background   : var(--primaria);
		border-radius: 4px;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
}

.over {
	position        : absolute;
	width           : auto;
	display         : flex;
	align-items     : center;
	height          : 2rem;
	padding         : 0;
	background-color: transparent;
	color           : var(--primaria);
	border          : none;
	z-index         : 500;
}

.fillScreen {
	flex: 1;
}

.centerStuff {
	display        : flex;
	flex-direction : column;
	justify-content: center;
}

:focus {
	outline: none;
}

html,
body {
	overflow-x     : hidden;
	scroll-behavior: smooth;
}

.btnTrans {
	background-color: transparent;
	color           : var(--primaria);
}

.btnBorder {
	background-color: transparent;
	color           : var(--primaria);
	border          : 1px solid var(--primaria);
}

.btnFullW {
	width: 100%;
}

.checkSuccess {
	color: var(--bs-success);
}

.crossDanger {
	color: var(--bs-danger);
}

.bgGradientGrey {
	background-color: #f7f7f7 !important;
}

@media (prefers-color-scheme: light) {
	:root {
		color           : #213547;
		background-color: #ffffff;
	}

	a:hover {
		color: #747bff;
	}

	button {
		background-color: var(--primaria);
	}
}

@keyframes timer {
	0% {
		color: transparent;
	}

	50% {
		color: var(--primaria);
	}

	100% {
		color: transparent;
	}
}

@keyframes pulse {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0.3;
	}

	100% {
		opacity: 1;
	}
}