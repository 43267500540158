#root {
  max-width    : 1280px;
  width        : 100%;
  /* text-align: center; */
}

.textCenter {
  text-align: center;
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.floatCenter {
  position  : absolute;
  top       : 0rem;
  width     : 100%;
  margin    : auto;
  text-align: center;
}

.App {
  height: 100vh;
  width : 100vw;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}